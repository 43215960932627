.upload-input {
  display: none;  
}
.primary-img {
    padding: 2px;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
}
.uploading {
    background-color: rgba(0, 0, 0, 0.3);;
}
.secondary-img {
  padding: 2px;
  border-radius: 6px;
  
}
.upload-delete {
  position: relative;
  left: -42px;
  top: 29px;
  margin-right: -42px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}
.upload-primary-delete {
    position: relative;
    top: -40px;
    left: 100px;
    margin-bottom: -40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
  }