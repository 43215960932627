.ais-Hits-item {
    list-style-type: none;
}
.ais-RefinementList-list {
    list-style-type: none;
}
.ais-RefinementList-count {
    padding-left: 8px;
    font-size: small;
}
.ais-Hits-list {
    border: 0;
    padding: 0; 
}

.hit-text {
    padding-left: 8px;
}

.ais-Hits-item a {
    text-decoration: none;
    color: black;
}

.ais-Hits-item a:hover {
    text-decoration: none;
    color: darkblue;
}

.refine-check {
    position: absolute;
    margin-top: 0.4rem;
    margin-left: -2rem;
    transform: scale(2);
}

.refine-label {
    margin-left: 1rem;
}
.search-label {
    margin-left: 1rem;
    margin-top: 0.3rem;
}
.admin-panel {
    background-color: bisque;
}